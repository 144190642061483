import { createContext, FunctionComponent, ReactNode, useContext, useEffect } from 'react';
import { IDENTITY_STORAGE_KEY, JWT_STORAGE_KEY, WEAK_JWT_STORAGE_KEY } from '../../api/auth';
import useLocalStorage from '../../hooks/useLocalStorage';
import AuthObject, { SaveJwtAndIdentityIdArgs } from './AuthObject';
import { useQueryClient } from '@tanstack/react-query';
import sendMessageToChrome from '../../chrome-extension/communication_utils/sendMessageToChrome';
import isJSObject from '../../utils/isJSObject';

export const AuthContext = createContext<AuthObject | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [jwt, setJwt, prevJwt] = useLocalStorage(JWT_STORAGE_KEY, null);
  const [identityId, setIdentityId] = useLocalStorage(IDENTITY_STORAGE_KEY, null);
  const [isWeak, setIsWeak] = useLocalStorage(WEAK_JWT_STORAGE_KEY, 'false');
  const queryClient = useQueryClient();

  const saveJwtAndIdentityId = ({ jwt, identityId, isWeak = false }: SaveJwtAndIdentityIdArgs) => {
    setJwt(jwt);
    setIdentityId(identityId);
    setIsWeak(isWeak.toString());
  };

  const logout = () => {
    setJwt(null);
    setIdentityId(null);
    setIsWeak('false');
    queryClient.clear();
  };

  useEffect(() => {
    if (prevJwt !== undefined && prevJwt !== null && prevJwt !== jwt && jwt !== null) {
      // If the jwt has changed, reset the query cache
      // Can either be driven by a login in this tab or a login in another tab
      // that updates the local storage
      // Needs to be jwt and not identityId because the jwt is what actually changes our
      // results from the BE
      queryClient.resetQueries();
    }
  }, [jwt, prevJwt]);

  useEffect(() => {
    if (isWeak !== 'true') {
      sendMessageToChrome({
        message: {
          type: 'AuthUpdatedMessage',
          jwt,
          identityId,
        },
      });
    }
  }, [jwt, identityId, isWeak]);

  useEffect(() => {
    window.addEventListener('message', function (event) {
      // Verify the sender's origin
      if (event.origin !== window.location.origin || !isJSObject(event.data) || !('type' in event.data))
        return;

      // In case the content script loads after the app
      if (event.data.type === 'PollWebAuthState') {
        if (isWeak !== 'true') {
          sendMessageToChrome({
            message: {
              type: 'AuthUpdatedMessage',
              jwt,
              identityId,
            },
          });
        }
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ saveJwtAndIdentityId, logout, identityId, jwt, isWeak: isWeak === 'true' }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hooked to have a fully typed AuthContext
export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) throw new Error('No AuthContext.Provider found when calling useAuthContext.');
  return authContext;
};
