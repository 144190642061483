import AppToContentScriptMessage from './message_types/AppToContentScriptMessage';

type Args = {
  message: AppToContentScriptMessage;
};

const sendMessageToChrome = ({ message }: Args) => {
  window.postMessage(message, window.location.origin);
};

export default sendMessageToChrome;
