import { FunctionComponent, useEffect, useState } from 'react';
import PikInput, { PikInputProps } from './PikInput';

export type PikValidatedInputProps = {
  isError: (value: string) => boolean;
  showLabel?: boolean;
  forceError?: boolean;
} & PikInputProps;

const PikValidatedInput: FunctionComponent<PikValidatedInputProps> = ({
  isError,
  showLabel = false,
  forceError,
  errorMessage,
  onChange,
  disabled,
  useAbsoluteError,
  onBlur,
  value,
  ...remainingProps
}) => {
  const [error, setError] = useState<boolean>(forceError || false);

  useEffect(() => {
    if (disabled) {
      setError(false);
    }
  }, [disabled, setError]);

  useEffect(() => {
    // Listen for changes to forceError
    // forceError can turn on error. but can't turn off error.
    if (forceError !== undefined && error !== forceError && forceError) {
      setError(forceError);
    }
  }, [forceError, error]);

  useEffect(() => {
    // List for externally driven changes to value removing the error
    if (error) {
      setError(isError(value));
    }
  }, [value]);

  return (
    <PikInput
      onChange={(value, rawEvent) => {
        onChange(value, rawEvent);
        if (error) {
          setError(false);
        }
      }}
      errorMessage={error ? errorMessage : undefined}
      disabled={disabled}
      showLabel={showLabel}
      useAbsoluteError={useAbsoluteError}
      onBlur={(e) => {
        setError(isError(value));
        if (onBlur !== undefined) {
          onBlur(e);
        }
      }}
      value={value}
      {...remainingProps}
    />
  );
};

export default PikValidatedInput;
